<template>
    <div class="ces-main" style="height: 100%">
        <div class="boxbutton">
            <el-row>
                <el-col :span="16">
                    <div class="grid-content bg-purple">
                        <!--<el-button type="primary" plain size="mini" v-if="interfaceMonitSearchFlag" @click="interfaceMonitSearch">查询</el-button>-->
                        <el-button type="primary" plain size="mini" v-if="interfaceMonitResetFlag" @click="interfaceMonitReset">重置</el-button>
                        <el-button type="primary" plain size="mini" v-if="interfaceMonitDelFlag" @click="interfaceMonitDel">删除</el-button>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <el-input maxlength="50" placeholder="请输入接口名称" v-model="AccurateAndUnique_interName" size="mini" style="width: 250px"  class="input-with-input">
                            <el-button slot="append" icon="el-icon-search" @click="handleQuery"></el-button>
                        </el-input>
                        <el-button type="primary" size="mini" icon="el-icon-zoom-in" style="float: right" @click="dialog = true">高级查询</el-button>
                    </div>
                </el-col>
                <el-drawer
                        title="接口监控高级查询条件"
                        :before-close="cancelForm"
                        :visible.sync="dialog"
                        direction="rtl"
                        custom-class="demo-drawer"
                        ref="drawer"
                >
                    <div class="demo-drawer__content">
                        <el-row>
                            <el-col>
                                <div class="grid-content bg-purple">
                                    <el-card class="box-card">
                                        <div>
                                            <el-form label-width="140px" :model="dataForms" ref="dataForms" size="mini" label-position="left">
                                                <el-form-item label="任务状态" prop="status">
                                                    <el-select v-model="dataForms.status" placeholder="请选择单据状态" style="width: 220px">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="初始" value="0"></el-option>
                                                        <el-option label="执行中" value="1"></el-option>
                                                        <el-option label="执行失败" value="2"></el-option>
                                                        <el-option label="执行成功" value="3"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="接口类型" prop="interType">
                                                    <el-select v-model="dataForms.interType" placeholder="请选择物流状态状态" style="width: 220px">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="接入" value="0"></el-option>
                                                        <el-option label="输出" value="1"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="创建开始时间" prop="createTimeBegin">
                                                    <el-date-picker
                                                            v-model="dataForms.createTimeBegin"
                                                            type="datetime"
                                                            placeholder="选择日期时间"
                                                            align="right"
                                                            :picker-options="pickerOptions">
                                                    </el-date-picker>
                                                </el-form-item>
                                                <el-form-item label="创建结束时间" prop="createTimeEnd">
                                                    <el-date-picker
                                                            v-model="dataForms.createTimeEnd"
                                                            type="datetime"
                                                            placeholder="选择日期时间"
                                                            align="right"
                                                            :picker-options="pickerOptions">
                                                    </el-date-picker>
                                                </el-form-item>
                                            </el-form>
                                            <div slot="footer" class="dialog-footer" style="text-align: center">
                                                <!--<el-button type="primary" @click="$refs.drawer.closeDrawer()" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>-->
                                                <el-button size="mini" v-if="interfaceMonitSearchFlag" type="primary" @click="handleClose" :loading="loading">{{ loading ? '提交中 ...' : '查 询' }}</el-button>
                                                <el-button size="mini" type="warning" @click="cancelForm">关闭</el-button>
                                            </div>
                                        </div>
                                    </el-card>
                                </div>
                            </el-col>

                        </el-row>
                    </div>
                </el-drawer>
            </el-row>
        </div>
        <div class="tableMain">
            <ces-table
                    size='mini'
                    :isSelection='true'
                    :isIndex='true'
                    :isPagination='true'
                    :isHandle='true'
                    v-loading='loading'
                    :tableData='tableData'
                    :tableCols='tableCols'
                    :pagination='pagination'
                    @refresh="handleCurrentChange"
                    ref='cesTable'
            >
            </ces-table>
        </div>
    </div>
</template>

<script>
    //引入组件
    import cesTable from '../../components/common/table.vue'
    import Drawer from '../../components/common/drawer.vue'
    import axios from 'axios'
    import arrayQuery from '../../components/arrayQuery.js'
    import qs from 'qs'
    import 'element-ui/lib/theme-chalk/index.css'
    export default {
        data(){
            return {
                table: false,
                dialog: false,
                loading: false,
                pickerOptions: {
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '一周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }]
                },
                AccurateAndUnique_interName:'',
                dataForms:{
                    interName:'',
                    interDesc: '',
                    interType:'',
                    status:'',
                    createTimeBegin:'',
                    createTimeEnd:''
                },
                formLabelWidth: '80px',
                timer: null,
                searchData:{ //查询表单的对应的值
                    orderNo:''
                },
                searchForm:[],
                apiId:'null',
                fieldList:[],
                // 表格
                tableData:[],//表格数据
                tableCols:[ //表格列数据
                    {label:'接口id',prop:'id',width:'160',align:'center'},
                    {label:'接口名称',prop:'interName',width:'170',align:'center'},
                    {label:'任务状态',prop:'status',align:'center',
                        formatter: (row) => {
                            if (row.status == 0) {
                                return "初始";
                            } else if (row.status == 1) {
                                return "执行中";
                            } else if (row.status == 2) {
                                return "执行失败";
                            } else if (row.status == 3) {
                                return "执行成功";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'接口描述',prop:'interDesc',width:'170',align:'center'},
                    {label:'接口类型',prop:'interType',align:'center',
                        formatter: (row) => {
                            if (row.interType == 0) {
                                return "接入";
                            } else if (row.interType == 1) {
                                return "输入";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'创建开始时间',prop:'createTimeBegin',width:'160',align:'center',
                        formatter: (row, column) => {
                            if(row.createTimeBegin == null){
                                return ''
                            }else {
                                return this.$moment(row.createTimeBegin).format("YYYY-MM-DD HH:mm:ss")
                            }

                        }
                    },
                    {label:'创建结束时间',prop:'createTimeEnd',width:'160',align:'center',
                        formatter: (row, column) => {
                            if(row.createTimeEnd == null){
                                return ''
                            }else {
                                return this.$moment(row.createTimeEnd).format("YYYY-MM-DD HH:mm:ss")
                            }

                        }
                    },
                    {label:'错误信息',prop:'errMsg',align:'center',width:'160'},
                    {label:'创建时间',prop:'createTime',align:'center',width:'160',
                        formatter: (row, column) => {
                            if(row.createTime == null){
                                return ''
                            }else {
                                return this.$moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
                            }

                        }
                    },
                    {label:'最后一次修改时间',prop:'modifyTime',align:'center',width:'160',
                        formatter: (row, column) => {
                            if(row.modifyTime == null){
                                return ''
                            }else {
                                return this.$moment(row.modifyTime).format("YYYY-MM-DD HH:mm:ss")
                            }

                        }
                    }
                ],
                tableHandles:[  //这是表格和表单之间的一个按钮，我的项目不需要
                    {label:'新增',type:'primary',handle:row=>''}
                ],
                pagination:{ //分页数据
                    pageSize:50,
                    pageNum:1,
                    total:0
                },
                merge:[],
                interfaceMonitSearchFlag:true,
                interfaceMonitResetFlag:true,
                interfaceMonitDelFlag:true
            }
        },

        components:{ //引入组件后注册组件
            cesTable,
            Drawer
        },
        mounted(){
            this.getTableAndForm();
            let parentId = '2_5_1';
            let currentPageButtons = arrayQuery.text(parentId);
            let _this = this;
            _this.currentPageButtons = currentPageButtons;
            //查询按钮权限
            let interfaceMonitSearch = currentPageButtons.find(item => {
                return item.menuId == '2_5_1_1';
            });
            if(interfaceMonitSearch == undefined){
                _this.interfaceMonitSearchFlag = false;
            }else {
                _this.interfaceMonitSearchFlag = true;
            }
            //重置按钮权限
            let interfaceMonitReset = currentPageButtons.find(item => {
                return item.menuId == '2_5_1_2';
            });
            if(interfaceMonitReset == undefined){
                _this.interfaceMonitResetFlag = false;
            }else {
                _this.interfaceMonitResetFlag = true;
            }
            //删除按钮权限
            let interfaceMonitDel = currentPageButtons.find(item => {
                return item.menuId == '2_5_1_3';
            });
            if(interfaceMonitDel == undefined){
                _this.interfaceMonitDelFlag = false;
            }else {
                _this.interfaceMonitDelFlag = true;
            }
        },
        methods:{
            //此方法是点击分页时触发的查询，
            handleCurrentChange(){
                this.interfaceMonitSearch()
            },
            //获取表格和表单数据
            async getTableAndForm(){
                //...
                this.interfaceMonitSearch()
            },
            handleQuery(){ //查询
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                //快捷查询
                this.loading = true;
                const data = new URLSearchParams();
                data.append('interName', this.AccurateAndUnique_interName);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/job/urbanInter/queryTasks`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.items;
                    this.pagination.total = res.data.total;
                    this.loading = false;
                });
            },
            // 获取表格勾选数据
            selectionLineChangeHandle(val){
                this.merge = this.$refs.cesTable.multipleSelection
            },
            //查询
            interfaceMonitSearch(){
                this.loading = true;
                let createTimeBegin;
                let createTimeEnd;
                if(this.dataForms.createTimeBegin == '' || this.dataForms.createTimeBegin == null){
                    createTimeBegin = ''
                }else {
                    createTimeBegin = this.$moment(this.dataForms.createTimeBegin).format("YYYY-MM-DD")
                }
                if(this.dataForms.createTimeEnd == '' || this.dataForms.createTimeEnd == null){
                    createTimeEnd = ''
                }else {
                    createTimeEnd = this.$moment(this.dataForms.createTimeEnd).format("YYYY-MM-DD")
                }
                const data = new URLSearchParams();
                data.append('interName', this.dataForms.interName);
                data.append('interDesc', this.dataForms.interDesc);
                data.append('interType', this.dataForms.interType);
                data.append('status', this.dataForms.status);
                data.append('createTimeBegin', createTimeBegin);
                data.append('createTimeEnd', createTimeEnd);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/job/urbanInter/queryTasks`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.items;
                    this.pagination.total = res.data.total;
                    this.loading = false;
                });
            },
            interfaceMonitSearch_Multiple(){
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                this.interfaceMonitSearch();
            },
            //重置
            interfaceMonitReset(){
                this.selectionLineChangeHandle();
                var ids = [];
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '接口重置操作必须选中一条数据'
                    });
                }else{
                    for(var i = 0;i<this.merge.length;i++){
                        ids.push(this.merge[i].id)
                    }
                    axios({
                        method: 'post',
                        url: `${this.$apiPath}/api/job/urbanInter/reset`,
                        data:ids
                    }).then(res=> {
                        if(res.data.success == true){
                            this.$message({
                                message: '接口重置成功',
                                type: 'success'
                            });
                            this.interfaceMonitSearch();
                        }else {
                            this.$alert(res.data.msg, '提示', {
                                confirmButtonText: '确定',
                            });

                        }
                    });
                }
            },
            //删除
            interfaceMonitDel(){
                this.selectionLineChangeHandle();
                var ids = [];
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '接口重置操作必须选中一条数据'
                    });
                }else{
                    this.$confirm('此操作将对接口进行批量删除, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        for(var i = 0;i<this.merge.length;i++){
                            ids.push(this.merge[i].id)
                        }
                        axios({
                            method: 'post',
                            url: `${this.$apiPath}/api/job/urbanInter/delete`,
                            data:ids
                        }).then(res=> {
                            if(res.data.success == true){
                                this.$message({
                                    message: '接口删除成功',
                                    type: 'success'
                                });
                                this.interfaceMonitSearch();
                            }else {
                                this.$alert(res.data.msg, '提示', {
                                    confirmButtonText: '确定',
                                });

                            }
                        });
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消操作'
                        });
                    });
                }

            },
            handleClose(done) {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                this.timer = setTimeout(() => {
                    // 动画关闭需要一定的时间
                    setTimeout(() => {
                        this.loading = false;
                    }, 400);
                    this.interfaceMonitSearch_Multiple();
                    // 动画关闭需要一定的时间
                    this.dialog = false;
                }, 2000);
            },
            cancelForm() {
                this.loading = false;
                this.dialog = false;
                clearTimeout(this.timer);
            }
        }
    }
</script>
<style>
    .tableMain .ces-table-page{
        height: 100%;
    }
    .tableMain .ces-table{
        height: 100%;
    }
    .tableMain .el-table th{
        padding: 0;
        height: 35px;
    }
    .tableMain .el-table td{
        padding: 0;
        height: 35px;
    }
</style>
<style scoped>

    .boxbutton{
        width: 100%;
        height: 40px;
    }
    .tableMain{
        width: 100%;
        height: 82%;
    }
</style>
